import { IndicatorService } from '../../services/indicator.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import {
	faCircle,
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { catchError, filter } from 'rxjs/operators';
import { LoggingService } from '@services/logging.service';
import { AllDashboardData } from '@models/seleced-dropdown-data.model';
import { DashboardCommunicationService } from '@services/dashboard-communication.service';
import { Title } from '@angular/platform-browser';
import { ToastMessageService } from '@services/toast-message.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	faExclamationTriangle = faExclamationTriangle;
	faCircle = faCircle;
	destroyed$ = new Subject<void>();
	isEDMConnect = false;
	isMappingConnect = false;
	isImportConnect = false;
	appHeaderName: string = '';

	wellName: String = '';
	wellBoreName: String = '';
	dailyReport: String = '';

	isRouterDisplay = false;

	menuButtonIcon: string = 'pi pi-bars';

	private subscriptions: Subscription[] = [];

	constructor(
		public router: Router,
		public authService: AuthService,
		private indicatorService: IndicatorService,
		private loggingService: LoggingService,
		private dashboardCommunicationService: DashboardCommunicationService,
		private titleService: Title,
		private toastMessageService: ToastMessageService,
	) { }

	ngOnInit() {
		this.initTitle();
		this.subscriptions.push(
			this.authService.loggedIn.pipe(filter((e) => e)).subscribe((e) => {
				if (this.authService.isReadWriteUser || this.router.url == '/uploader') {
					this.checkConnection();
				} else {
					this.toastMessageService.internalErrorMessage('Permission required', 'You do not have sufficient permissions to access this page.')
				}
			}),
		);

		this.routeEvent(this.router);
	}

	routeEvent(router: Router){
		router.events.subscribe(e => {
			if(e instanceof NavigationEnd){
				if(e.url == '/view-data' || e.url == '/confirmation') {
					this.dashboardCommunicationService.allDashboardData$
					.pipe(
						catchError((error) => {
							this.loggingService.logException(error, 3);
							return throwError(error);
						})
					)
					.subscribe((dashboard_data: AllDashboardData) => {
						this.wellName = dashboard_data.selectedWell.well_common_name;
						this.wellBoreName = dashboard_data.selectedWellbore.wellbore_name;
						this.dailyReport = dashboard_data.selectedDailyReport.format_date_report;
					});	
					this.isRouterDisplay = true;
				} else if (e.url == '/dashboard') {
					this.isRouterDisplay = false;
				}
			}
		});
	}

	private checkConnection() {
		this.indicatorService.checkAllConnection()
		.subscribe( (results: any) => {
			this.indicatorService.isImportConnected$.next(results[2]);
			this.indicatorService.isEDMConnected$.next(results[0]);
			this.indicatorService.isMappingConnected$.next(results[1]);
			this.indicatorService.isImportConnected$.pipe(filter(e => e != null)).subscribe(e => {
				this.isImportConnect = !!e;
			});
			this.indicatorService.isMappingConnected$.pipe(filter(e => e != null)).subscribe(e => {
				this.isMappingConnect = !!e;
			});
			this.indicatorService.isEDMConnected$.pipe(filter(e => e != null)).subscribe(e => {
				this.isEDMConnect = !!e;
			});
		});
	}

	homeReload(): void {
		this.router.navigate(['/dashboard']);
		this.loggingService.logPageView('reload dashboard', '/dashboard');
	}

	async initTitle() {
		this.router.events.subscribe((event) => {
			if(event instanceof NavigationStart) {
				let title = event.url.substring(1);
				switch(title){
					case 'uploader':
						this.titleService.setTitle('iDDR Uploader')
						this.appHeaderName = 'iDDR UPLOADER';
						break;
					default: 
						this.titleService.setTitle('iDDR Automation')
						this.appHeaderName = 'iDDR AUTOMATION';
						break;
				}
			}
		});
	}

	onMenuBtnClick() {
		let getMenuScreen = document.getElementsByClassName("iDDR__menu-layout")[0];
		let getTemplateWrapper = document.getElementsByClassName("template-wrapper")[0];
		if(!getMenuScreen.classList.contains('menu-collapse')) {
			getMenuScreen.classList.add("menu-collapse");
			getMenuScreen.querySelectorAll(".menu-title").forEach(item => item.setAttribute("hidden", ""));
			getTemplateWrapper.classList.add("collapse");
			this.dashboardCommunicationService.triggerTemplateWrapperCollapse('collapse');
		} else {
			getMenuScreen.classList.remove("menu-collapse");
			getMenuScreen.querySelectorAll(".menu-title").forEach(item => item.removeAttribute("hidden"));
			getTemplateWrapper.classList.remove("collapse");
			this.dashboardCommunicationService.triggerTemplateWrapperCollapse('');
		}
	}
	
	ngOnDestroy(): void {
		this.destroyed$.next();
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
