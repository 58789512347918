import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvService } from './env.service';
import { ToastMessageService } from './toast-message.service';

@Injectable({
	providedIn: 'root',
})
export class RefreshWellListService {
	public iswellListSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	data = this.iswellListSaved$.asObservable();

	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	constructor(
		private http: HttpClient,
		private env: EnvService,
		private toastMessageService: ToastMessageService
	) {}

	// API get connection status
	getWellList(): any {
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getwelllist`;
		const options = {
			params: {},
			reportProgress: true,
		};

		return this.http
			.get(url, options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('get well list');
						console.error('Time out while get well list');
						return throwError(error);
					}
				})
				);
	}

	triggerGetWellList(item) {
		this.iswellListSaved$.next(item);
	}
}
