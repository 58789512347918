import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { UploadFileResponse } from '@models/upload-file-response.model';
import { MultiDropdownList } from '@models/filter-by-wellid-response.model';
import {
	ImportTypes,
	FileExample,
	FileReleaseNote,
	DbQueryParams,
	DbQueryParamsRetrieve,
	FileType,
} from '@models/file-type.model';
import { Well } from '@models/well.model';
import { ParentEdmDataClass } from '@models/edmdata.model';
import { EnvService } from './env.service';
import { LoggingService } from './logging.service';
import { catchError } from 'rxjs/operators';
import { ToastMessageService } from './toast-message.service';
import { Buffer } from 'buffer';

@Injectable({
	providedIn: 'root',
})

// Handle dashboard API call
export class DashboardService {
	token = '';
	options = {
		params: new HttpParams(),
		reportProgress: true,
	};

	constructor(
		private http: HttpClient,
		private env: EnvService,
		private loggingService: LoggingService,
		private toastMessageService: ToastMessageService
	) {}

	getWellList(): Observable<Well[]> {
		this.loggingService.logEvent('get well list');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getwelllist`;
		return this.http
			.get<Well[]>(url, this.options)
	}

	getOperatorList(): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get operator list');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getoperatorlist`;
		return this.http
			.get<MultiDropdownList>(url, this.options)
	}

	getProjectList(): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get operator list');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getAllProject`;
		return this.http
			.get<MultiDropdownList>(url, this.options)
	}

	getSiteList(): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get operator list');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getAllSites`;
		return this.http
			.get<MultiDropdownList>(url, this.options)
	}

	getFilterByOperatorId(operatorId: any): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get project by operator id');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getListProjectByPolicyId`;
		return this.http
			.post<MultiDropdownList>(url, operatorId, this.options)
	}

	getFilterByProjectId(projectId: any): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get site by project id');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getListSiteByProjecId`;
		return this.http
			.post<MultiDropdownList>(url, projectId, this.options)
	}

	getFilterBySiteId(siteId: any): Observable<Well[]> {
		this.loggingService.logEvent('get well by site id');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getListWellBySiteId`;
		return this.http
			.post<Well[]>(url, siteId, this.options)
	}

	getFilterByWellId(wellId: any): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get wellbore by wellId');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getdropdownlistById`;
		return this.http
			.post<MultiDropdownList>(url, wellId, this.options)
	}

	getFilterByWellboreId(
		wellId: string,
		wellboreId: string
	): Observable<MultiDropdownList> {
		this.loggingService.logEvent('get daily report by wellboreId');
		let url = `${this.env.W_Connections_EdmHost}/api/edmdata/getdropdownlistByWellboreId`;
		return this.http
			.post<MultiDropdownList>(url, {wellId: wellId, wellboreId: wellboreId}, this.options)
	}

	uploadReportFiles(files: FormData, connectionId: string = null): Observable<UploadFileResponse> {
		this.loggingService.logEvent('upload report files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/processmany`;
		let newOptions = {
			params: new HttpParams(),
			reportProgress: true,
			headers: {
				signalRId: connectionId
			}
		};
		return this.http
			.post<UploadFileResponse>(url, files, newOptions)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('report files');
						console.error('Time out while uploading files');
						return throwError(error);
					}
				})
			)
	}

	getProcessData(importedData: any): Observable<ParentEdmDataClass> {
		this.loggingService.logEvent('process mapping data');
		let url = `${this.env.W_Connections_MappingHost}/api/mapping/map`;
		return this.http
			.post<ParentEdmDataClass>(url, 	importedData, this.options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('processing data');
						console.error('Time out while processing data');
						return throwError(error);
					}
				})
			)
	}

	getListFileType(): Observable<ImportTypes> {
		this.loggingService.logEvent('get list file type');
		let url = `${this.env.W_Connections_ImportHost}/api/fileinfo/filetypes`;
		return this.http
			.get<ImportTypes>(url, this.options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('file type');
						console.error('Time out while getting file type');
						return throwError(error);
					}
				})
			)
	}

	getListFileExample(): Observable<FileExample[]> {
		let url = `${this.env.W_Connections_ImportHost}/api/fileinfo/fileexamples`;
		return this.http
			.get<FileExample[]>(url, this.options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('file example');
						console.error('Time out while getting file example');
						return throwError(error);
					}
				})
			)
	}

	getListFileReleaseNote(): Observable<FileReleaseNote[]> {
		let url = `${this.env.W_Connections_ImportHost}/api/fileinfo/filestoreReleaseNotes`;
		return this.http
			.get<FileReleaseNote[]>(url, this.options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('release notes');
						console.error('Time out while getting release notes');
					} else {
						this.toastMessageService.internalErrorMessage(null, 'Cannot get the release note data');
					}
					return throwError(error);
				})
			)
	}

	getWellWellboreList(): Observable<[]> {
		let url = `${this.env.W_Connections_ImportHost}/api/import/stored/wellInfo`;
		return this.http
			.get<[]>(url, this.options)
			.pipe();
	}

	deleteStoredFiles(queryParams: DbQueryParams): Observable<number> {
		this.loggingService.logEvent('delete stored files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/stored/delete`;
		return this.http
			.post<number>(url, queryParams, this.options)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('deleting cloud files');
						console.error('Time out while getting delete cloud file');
						return throwError(error);
					}
				})
			);
	}

	getStoredFiles(queryParams: DbQueryParams): Observable<UploadFileResponse> {
		this.loggingService.logEvent('get all stored files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/stored/retrieve`;
		return this.http
			.post<UploadFileResponse>(url, queryParams, this.options);
	}

	mergeDataFile(dataMerge: FormData, connectionId: string = null): Observable<UploadFileResponse> {
		this.loggingService.logEvent('merge data files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/merge`;
		let newOptions = {
			params: new HttpParams(),
			reportProgress: true,
			headers: {
				signalRId: connectionId
			}
		};
		return this.http
			.post<UploadFileResponse>(url, dataMerge, newOptions)
			.pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('merge data files');
						console.error('Time out while merging data files');
						return throwError(error);
					}
				})
			);
	}

	refreshFileType(fileTypes: FileType[]): Observable<UploadFileResponse> {
		this.loggingService.logEvent('refresh data files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/refresh`;
		return this.http
			.post<UploadFileResponse>(url, fileTypes, this.options)
			.pipe();
	}

	uploadFiles(files: FormData, connectionId: string = null): Observable<FileType[]> {
		this.loggingService.logEvent('upload report files');
		let url = `${this.env.W_Connections_ImportHost}/api/import/uploader/add`;
		let newOptions = {
			params: new HttpParams(),
			reportProgress: true,
			headers: {
				signalRId: connectionId
			}
		};
		return this.http
			.post<FileType[]>(url, files, newOptions);
	}

	validateDateFormatString(dateStr: string){
        const reg = new RegExp(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/g);
        return reg.test(dateStr);
    }

    //Encode-Decode Data
    handleConvertData(inputValue: any, flag: string){
        if(flag == 'encode' && typeof(inputValue) == 'string' && !this.validateDateFormatString(inputValue) ){
            return Buffer.from(inputValue).toString('base64');
        }
        if(flag == 'decode' && typeof(inputValue) == 'string' && !this.validateDateFormatString(inputValue)){
            return Buffer.from(inputValue, 'base64').toString('binary');
        }
        return inputValue;
    }

	recursionEnDecodeObj(obj: any, flag: string){
		
        for(let item in obj){
            if(obj[item] === null){
                this.handleConvertData(obj[item], flag);
            }else{
                obj[item] = (typeof(obj[item]) != 'object') ? this.handleConvertData(obj[item], flag) : this.recursionEnDecodeObj(obj[item], flag)
            }
        }
        return obj;
    }
}
