import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faQuestionCircle, faListAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { MenuItemModel } from '@models/menu-item.model';
import { AuthService } from '@services/auth.service';
import { DashboardService } from '@services/dashboard.service';
import { IndicatorService } from '@services/indicator.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  // menu items
  menuItems: MenuItemModel[] = [];
  openedMenuStyle: string = '';
	menuButtonIcon: string = 'pi pi-bars';
	isDisableLink: boolean;
	isImportConnected: boolean;
	isFileExampleDisplay: boolean;

	// File example
	fileList = [];
	fileExample = [];

	// Icon
	faGlobe = faGlobe;
	faListAlt = faListAlt;
	faQuestionCircle = faQuestionCircle;

  private subscriptions: Subscription[] = [];

  constructor(
		private authService: AuthService,
		private dashboardService: DashboardService,
		private router: Router,
		private indicatorService: IndicatorService,
	) { }

	ngOnInit(): void {
	}

	onReceiveStatus(event) {
		let status = event;
		if(this.openedMenuStyle == '' && status == 'opened') {
			this.openedMenuStyle = 'opened';
			this.menuButtonIcon = 'pi pi-times';
		} else {
			this.openedMenuStyle = '';
			this.menuButtonIcon ='pi pi-bars';
		}
	}

	onMenuItemClick(event): void {
		let linkLabel = event.label;

		switch(linkLabel) {
			case 'Dashboard':
				this.router.navigate(['dashboard']);
				break;
			case 'File Example':
				this.isFileExampleDisplay = true;
				break;
			case 'Troubleshooting':
				this.openTroubleshootingPanel();
				break;
		}
	}

	ngAfterViewInit(): void {
		this.subscriptions.push(
			this.indicatorService.isImportConnected$.subscribe((response: boolean) => {
				if(response) {
					this.isDisableLink = true;
					this.dashboardService.getListFileExample().subscribe((res) => {
						this.fileExample = res;
					});
				}
				this.menuItems = [
					{label: 'Dashboard', icon: this.faGlobe, title: 'Dashboard'},
					{label: 'File Example', icon: this.faListAlt, title: 'File Example', disabled: !this.isDisableLink},
					{label: 'Troubleshooting', icon: this.faQuestionCircle, title: 'Troubleshooting'},
				];
			})
		);
	}

	private openTroubleshootingPanel(): void {
		let getMainScreen = document.getElementsByClassName("app__main-screen")[0];
		let getTroubleshooting = document.getElementsByClassName("troubleshooting--screen")[0];
		if(getTroubleshooting.classList.contains('opened')) {
			getMainScreen.classList.remove('collapse');
			getTroubleshooting.classList.remove('opened');
		} else {
			getMainScreen.classList.add('collapse');
			getTroubleshooting.classList.add('opened');
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
}
