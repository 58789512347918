import { Injectable, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from 'app/commons/error-dialog/error-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ErrorDialogService {
	private isDisplay: boolean = false;
	public isDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	constructor(private modalService: NgbModal) {}

	// openErrorDialog(status?: number, area?: string) {
	// 	let checkDisplay = this.isDisplayed$.getValue();
	// 	if(checkDisplay) {
	// 		return false;
	// 	}
	// 	this.isDisplay = true;
	// 	let modalRef = this.modalService.open(ErrorDialogComponent, { windowClass: 'modal-holder', centered: true });
	// 	modalRef.componentInstance.display = this.isDisplay;
	// 	modalRef.componentInstance.status = status;
	// 	modalRef.componentInstance.area = area;
	// }

	triggerDisplay(trigger: boolean) {
		this.isDisplayed$.next(trigger);
	}
}
