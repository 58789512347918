<div class="trouble-shooting__panel">
	<div class="panel__header-content mb-2">
		<h2 class="panel-header pb-1">Troubleshooting</h2>
		<span class="panel--close-icon" (click)="closeTroubleshooting()"><i class="pi pi-times"></i></span>
	</div>
	<div class="panel__body-content">
		<p-fieldset
			class="common-fieldset mb-2"
			legend="Issues related to files users are trying to upload using iDDR"
			[toggleable]="true"
			[collapsed]="true"
		>
			<ul class="trouble-list">
				<li *ngFor="let item of listImportTrouble">
					<h3 class="title">{{ item.title }}</h3>
					<p-lightbox
						[images]="item.images"
						easing="ease-in"
						class="common-lightbox"
					></p-lightbox>
					<div class="description">
						<span>Description: </span>{{ item.description }}
					</div>
					<div class="solution">
						<span>Solution: </span>{{ item.solution }}
					</div>
				</li>
			</ul>
		</p-fieldset>

		<p-fieldset
			class="common-fieldset mb-2"
			legend="Issues related to iDDR/EDM mapping"
			[toggleable]="true"
			[collapsed]="true"
		>
			<ul class="trouble-list">
				<li *ngFor="let item of listMappingTrouble">
					<h3 class="title">{{ item.title }}</h3>
					<p-lightbox
						[images]="item.images"
						easing="ease-in"
						class="common-lightbox"
					></p-lightbox>
					<div class="description">
						<span>Description: </span>{{ item.description }}
					</div>
					<div class="solution">
						<span>Solution: </span>{{ item.solution }}
					</div>
				</li>
			</ul>
		</p-fieldset>

		<p-fieldset
			class="common-fieldset mb-2"
			legend="Issues related to OpenWells configuration"
			[toggleable]="true"
			[collapsed]="true"
		>
			<ul class="trouble-list">
				<li *ngFor="let item of listEDMTrouble">
					<h3 class="title">{{ item.title }}</h3>
					<p-lightbox
						[images]="item.images"
						easing="ease-in"
						class="common-lightbox"
					></p-lightbox>
					<div class="description">
						<span>Description: </span>{{ item.description }}
					</div>
					<div class="solution">
						<span>Solution: </span>{{ item.solution }}
					</div>
				</li>
			</ul>
		</p-fieldset>

		<p-fieldset
			class="common-fieldset mb-2"
			legend="Errors related to network/connection"
			[toggleable]="true"
			[collapsed]="true"
		>
			<ul class="trouble-list">
				<li *ngFor="let item of listToastMessageTrouble">
					<h3 class="title">{{ item.title }}</h3>
					<p-lightbox
						[images]="item.images"
						easing="ease-in"
						class="common-lightbox"
					></p-lightbox>
					<div class="description">
						<span>Description: </span>{{ item.description }}
					</div>
					<div class="solution">
						<span>Solution: </span>{{ item.solution }}
					</div>
				</li>
			</ul>
		</p-fieldset>

		<p-fieldset
			class="common-fieldset"
			legend="Errors related to iDDR Automation/Uploader configuration"
			[toggleable]="true"
			[collapsed]="true"
		>
			<ul class="trouble-list">
				<li *ngFor="let item of listConfigurationTrouble">
					<h3 class="title">{{ item.title }}</h3>
					<p-lightbox
						[images]="item.images"
						easing="ease-in"
						class="common-lightbox"
					></p-lightbox>
					<div class="description">
						<span>Description: </span>{{ item.description }}
					</div>
					<div class="solution">
						<span>Solution: </span>{{ item.solution }}
					</div>
				</li>
			</ul>
		</p-fieldset>
	</div>
</div>
