import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvService } from './env.service';
import { ToastMessageService } from './toast-message.service';

@Injectable({
	providedIn: 'root',
})
export class GetAppVersionService {
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	constructor(
		private http: HttpClient,
		private env: EnvService,
		private toastMessageService: ToastMessageService
	) {}

	public getAppVersion(): any {
		const url = `${this.env.W_Connections_EdmHost}/api/info/version`;
		const options = {
			params: {},
			reportProgress: true,
		};

		return this.http
			.get(url, options)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('getting version app');
						console.error('Time out while getting version app')
						return throwError(error);
					}
				})
			)
	}
}
