import { LoggingService } from './services/logging.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
	AuthenticationResult,
	EventMessage,
	EventType
} from '@azure/msal-browser';
import { AuthService } from '@services/auth.service';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DashboardCommunicationService } from '@services/dashboard-communication.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'ddrAuto.UI';
	private readonly _destroying$ = new Subject<void>();
	faTimesCircle = faTimesCircle;
	displayTroubleShooting: boolean;

	constructor(
		private msalService: MsalService,
		public authService: AuthService,
		private msalBroadcastService: MsalBroadcastService,
		private loggingService: LoggingService,
		private dashboardCommunicationService: DashboardCommunicationService
	) { }

	ngOnInit(): void {
		this.msalService.handleRedirectObservable().pipe(
			takeUntil(this._destroying$),
			catchError((error) => {
				this.loggingService.logException(error, 3);
				return throwError(error);
			})
		).subscribe({
			next: (result: AuthenticationResult) => {
				this.authService.updateLoggedInStatus();
			},
			error: (error) => {
				this.authService.acquireAccessToken();
			},
		});

		this.msalBroadcastService.msalSubject$
			.pipe(
				takeUntil(this._destroying$),
				catchError((error) => {
					this.loggingService.logException(error, 3);
					return throwError(error);
				})
			)
			.subscribe((result: EventMessage) => {
				if (result.eventType === EventType.LOGIN_SUCCESS) {
					const payload = result.payload as AuthenticationResult;
					if (payload.account) {
						this.msalService.instance.setActiveAccount(
							payload.account
						);
						this.reload();
					}
				}
			});
	}

	reload(){
		window.location.reload();
	}

	ngAfterViewInit(): void {
		this.dashboardCommunicationService.displayTroubleShooting$.subscribe((result: boolean) => {
			this.displayTroubleShooting = result; 
		});
	}

	ngOnDestroy(): void {
		// this.authService.destroy();
		this._destroying$.next(null);
		this._destroying$.complete();
	}
}

