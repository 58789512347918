import { environment } from "environments/environment";

export class EnvService {
	// The values that are defined here are the default values that can
	// be overridden by env.js

	public production = environment.production;
	public W_Connections_ImportHost = environment.W_Connections_ImportHost;
	public W_Connections_MappingHost = environment.W_Connections_MappingHost;
	public W_Connections_EdmHost = environment.W_Connections_EdmHost;
	public W_Connections_WorkflowHost = environment.W_Connections_WorkflowHost;
	public Auth_ClientId = environment.Auth_ClientId;
	public Auth_TenantId = environment.Auth_TenantId;
	public Auth_Scope = environment.Auth_Scope;
	public Application_Insights = environment.Application_Insights;
	public NODE_ENV = environment.NODE_ENV

	constructor() {}
}