import { Injectable } from '@angular/core';
import { FileReleaseNote, FileType } from '@models/file-type.model';
import { SaveReportDataRequest } from '@models/save-report-data-request.model';
import { MergeDataFiles } from '@models/upload-file-response.model';
import { BehaviorSubject, Subject } from 'rxjs';

import {
	AllDashboardData,
	DrillstringHeaderData,
	SurveyHeaderData,
} from '../models/seleced-dropdown-data.model';

@Injectable({
	providedIn: 'root',
})

// Handle dashboard data transfer
export class DashboardCommunicationService {
	public version: BehaviorSubject<string> = new BehaviorSubject<string>(
		null
	);
	public releaseNotes: BehaviorSubject<FileReleaseNote[]> =  new BehaviorSubject<FileReleaseNote[]>([]);

	// Initial dashboard data
	public iswellListSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);

	public isEnabledConfirmation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		true
	);

	public isFilesDirty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public isFileChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);

	public displayTroubleShooting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	// prevent user to access data
	data = this.iswellListSaved$.asObservable();

	public fileDataChange$: BehaviorSubject<MergeDataFiles> = new BehaviorSubject<MergeDataFiles>(
		null
	);

	// Dashboard data
	public fileList: any[] = [];
	public reportData$: BehaviorSubject<SaveReportDataRequest> = new BehaviorSubject<SaveReportDataRequest>(
		null
	);
	public allDashboardData$: BehaviorSubject<AllDashboardData> = new BehaviorSubject<AllDashboardData>(
		null
	);

	public drillstringHeaderData$: BehaviorSubject<DrillstringHeaderData> = new BehaviorSubject<DrillstringHeaderData>(
		null
	);

	public surveyHeaderData$: BehaviorSubject<SurveyHeaderData> = new BehaviorSubject<SurveyHeaderData>(
		null
	);

	// Table data
	public FileImportDatas$: BehaviorSubject<FileType[]> = new BehaviorSubject<
		FileType[]
	>([]);

	// Trigger functions in dashboard
	public isUploadTriggered$ = new Subject<any>();

	private isDisableSaveButton$: boolean = false;

	public accessToken$ = new BehaviorSubject<string>('');

	public isPopupDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	public isRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	public isTemplateWrapperCollapse$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor() {}

	triggerUpdateDrillstringHeaderData(
		drillstringHeaderData: DrillstringHeaderData
	) {
		this.drillstringHeaderData$.next(drillstringHeaderData);
	}

	triggerUpdateSurveyHeaderData(surveyHeaderData: SurveyHeaderData) {
		this.surveyHeaderData$.next(surveyHeaderData);
	}

	triggerUpdateAccessToken(accessToken: string) {
		this.accessToken$.next(accessToken);
	}

	triggerUpdateReportData(newReportDate: SaveReportDataRequest) {
		this.reportData$.next(newReportDate);
	}

	triggerConfirmation(isEnabled): void {
		this.isEnabledConfirmation$.next(isEnabled);
	}

	// Fetch well list when new connection string is sent
	triggerGetWellList(item): void {
		this.iswellListSaved$.next(item);
	}

	// Send dashboard data
	dispatchProcessedFiles(ProcessedDataFromFiles: FileType[]): void {
		this.FileImportDatas$.next(ProcessedDataFromFiles);
	}

	// dispatchReportData(saveReportDataRequest: SaveReportDataRequest): void {
	// 	this.reportData$.next(saveReportDataRequest);
	// }

	dispatchDropdownData(allDashboardData: AllDashboardData): void {
		this.allDashboardData$.next(allDashboardData);
	}

	// Store dashboard data
	storeAllSelectedFiles(fileList: any): void {
		this.fileList = fileList;
	}

	// Store table data in file detail component and create new data for process data file.
	triggerSelectDataFile(isTriggered: boolean): void {
		this.isUploadTriggered$.next(isTriggered);
	}
	removeFileFromFileList(fileName: string): void {
		this.fileList = this.fileList.filter((i) => i.name !== fileName);
	}

	getStateOfDisableSaveButton() {
		return this.isDisableSaveButton$;
	}

	setStateOfDisableSaveButton(state: boolean) {
		this.isDisableSaveButton$ = state;
	}

	triggerRefreshTableData(isTriggered: boolean): void {
		this.isRefreshing$.next(isTriggered);
	}

	triggerDisplayTroubleShooting(isTriggered: boolean): void {
		this.displayTroubleShooting$.next(isTriggered);
	}
	
	triggerTemplateWrapperCollapse(isCollapse: string): void {
		this.isTemplateWrapperCollapse$.next(isCollapse);
	}
}
