import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DashboardCommunicationService } from '@services/dashboard-communication.service';
import { DashboardService } from '@services/dashboard.service';
import { GetAppVersionService } from '@services/get-app-version.service';
import { IndicatorService } from '@services/indicator.service';
import { LoggingService } from '@services/logging.service';
import { ToastMessageService } from '@services/toast-message.service';
import { MenuItem } from 'primeng/api';
import { combineLatest, Subscription, throwError, TimeoutError } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  isDisplay: boolean;
  isAboutUsDisplay: boolean = false;
  isVersionHistoryDisplay: boolean = false;

	userName: string = null;

  isClickable: boolean = true;

  disableLinkFromEDM: boolean;
  disableLinkFromImport: boolean;
  disableLinkFromMapping: boolean;
  disableLinkAboutUs: boolean;

  titleImportConnection: string;
  titleEDMConnection: string;
  titleAboutUs: string;
  errorConnectTitle: string = 'Service is being not ready';

  userProfileItems: MenuItem[] = [{label: 'Logout', icon: 'pi pi-fw pi-power-off', command: e => this.logout()}];

  versionApp: string;
  buildApp: string;

  releaseNotesList: any = [];

  private subscriptions: Subscription[] = [];
  
  constructor(
    private authService: AuthService,
    private indicatorService: IndicatorService,
    private getAppVersionService: GetAppVersionService,
    private dashboardService: DashboardService,
		private dashboardCommunicationService: DashboardCommunicationService,
		private loggingService: LoggingService,
		private toastMessageService: ToastMessageService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.loggedIn.pipe(filter((e) => e)).subscribe((e) => {
        this.getAppVersionService.getAppVersion().subscribe((res) => {
          if (res) {
            var ver = res.split(",");
            this.versionApp = ver[0];
            this.buildApp = ver[1];
            this.dashboardCommunicationService.version.next(res);
          }
        });
        this.dashboardService.getListFileReleaseNote().pipe(take(1), catchError((error) => {
          this.loggingService.logException(error, 3);
          return throwError(error);
        })).subscribe((res) => {
          if(res.length <= 0) { return }
          this.dashboardCommunicationService.releaseNotes.next(res);
          this.releaseNotesList = res.length > 3 ? res.slice(0, 3) : res;
        })
      }),
      
    )
  }

  ngAfterViewInit() {
    this.userName = this.authService.accountName;
    if(this.userName != '') {
      this.isDisplay = true;
      combineLatest([
        this.indicatorService.isImportConnected$,
        this.indicatorService.isEDMConnected$,
        this.indicatorService.isMappingConnected$,
        this.dashboardCommunicationService.version
      ]).subscribe((results: any) => {
       let isConnectionFinished = results.every(item => item != null);
        if(!isConnectionFinished) {
          return;
        } else {
          // this.isClickable = true;
          this.disableLinkFromImport = !results[0];
          this.disableLinkFromEDM = !results[1];
          if (this.dashboardCommunicationService.version.getValue() == null || this.dashboardCommunicationService.version.getValue() == '') {
            this.disableLinkAboutUs = true;
            this.titleAboutUs = this.errorConnectTitle;
          }
          results[0] ? this.titleImportConnection = null : this.titleImportConnection = this.errorConnectTitle;
          results[1] ? this.titleEDMConnection = null : this.titleEDMConnection = this.errorConnectTitle;
          this.userProfileItems = [
            {label: 'About Us', icon: 'pi pi-fw pi-user-plus', disabled: this.disableLinkAboutUs, title: this.titleAboutUs, command: e => this.isAboutUsDisplay = true},
            {label: 'Version history', icon: 'pi pi-fw pi-copy', command: e => this.isVersionHistoryDisplay = true, disabled: this.disableLinkFromImport, title: this.titleImportConnection},
            {label: 'Logout', icon: 'pi pi-fw pi-power-off', command: e => this.logout()},
          ];
        }
      });
    } else {
      this.isDisplay = false;
    }
  }

  login() {
		this.authService.login();
	}

	logout() {
		this.authService.logout();
	}
  
}
