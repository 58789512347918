import { Component, OnInit } from '@angular/core';
import { TroubleModel } from '@models/trouble-shooting.model';
import { DashboardCommunicationService } from '@services/dashboard-communication.service';

@Component({
  selector: 'app-trouble-shooting',
  templateUrl: './trouble-shooting.component.html',
  styleUrls: ['./trouble-shooting.component.scss']
})
export class TroubleShootingComponent implements OnInit {
  // Trouble Image List
  imagesImportTrouble: any[] = [];
  imagesMappingTrouble: any[] = [];
  imagesEDMTrouble: any[] = [];
  imagesToastMessage: any[] = [];
  imagesConfiguration: any[] = [];

  // Trouble List
  listImportTrouble: TroubleModel[] = [];
  listMappingTrouble: TroubleModel[] = [];
  listEDMTrouble: TroubleModel[] = [];
  listToastMessageTrouble: TroubleModel[] = [];
  listConfigurationTrouble: TroubleModel[] = [];

  constructor(
    private dashboardCommunicationService: DashboardCommunicationService
  ) {
    this.imagesImportTrouble.push(
      [{source:'assets/images/trouble-shooting/import-error-file.jpg', thumbnail: 'assets/images/trouble-shooting/import-error-file_small.jpg', title:'There is an issue when importing this file'}],
      [{source:'assets/images/trouble-shooting/import-error-file-type.jpg', thumbnail: 'assets/images/trouble-shooting/import-error-file-type_small.jpg', title:'Unable to process file information as File Type'}],
      [{source:'assets/images/trouble-shooting/import-error-file-type-unknown.jpg', thumbnail: 'assets/images/trouble-shooting/import-error-file-type-unknown_small.jpg', title:'No importer for File Type Unknown-Unknown was found'}],
      [{source:'assets/images/trouble-shooting/import-error-duplicate-file-type.jpg', thumbnail: 'assets/images/trouble-shooting/import-error-duplicate-file-type_small.jpg', title:'Duplicate file type'}],
      [{source:'assets/images/trouble-shooting/edm-error-validation.jpg', thumbnail: 'assets/images/trouble-shooting/edm-error-validation_small.jpg', title:'One or more validation errors occurred'}],
      [{source:'assets/images/trouble-shooting/upload-error-file-type.jpg', thumbnail: 'assets/images/trouble-shooting/upload-error-file-type_small.jpg', title:'File is failed to upload. Unable to process file information as File Type'}],
    );

    this.imagesMappingTrouble.push(
      [{source:'assets/images/trouble-shooting/mapping-error.jpg', thumbnail: 'assets/images/trouble-shooting/mapping-error_small.jpg', title:'Errors were found while importing data'}],
      [{source:'assets/images/trouble-shooting/edm-error-save.jpg', thumbnail: 'assets/images/trouble-shooting/edm-error-save_small.jpg', title:'An error was encountered while trying to save'}],
    );

    this.imagesEDMTrouble.push(
      [{source:'assets/images/trouble-shooting/mapping-error-no-drillstring-survey-header.jpg', thumbnail: 'assets/images/trouble-shooting/mapping-error-no-drillstring-survey-header_small.jpg', title:'No Drillstring/Survey header information was found'}],
    );

    this.imagesToastMessage.push(
      [{source:'assets/images/trouble-shooting/time-out-error.jpg', thumbnail: 'assets/images/trouble-shooting/time-out-error_small.jpg', title:'Internal Timeout Error'}],
      [{source:'assets/images/trouble-shooting/service-is-not-available.jpg', thumbnail: 'assets/images/trouble-shooting/service-is-not-available_small.jpg', title:'Service is not available'}],
      [{source:'assets/images/trouble-shooting/don-not-have-permission.jpg', thumbnail: 'assets/images/trouble-shooting/don-not-have-permission_small.jpg', title:'You do not have sufficient permission to access this page'}],
    );
    
    this.imagesConfiguration.push(
      [{source:'assets/images/trouble-shooting/no-documents-were-found.jpg', thumbnail: 'assets/images/trouble-shooting/no-documents-were-found_small.jpg', title:'No documents were found for the selected Well/Wellbore'}],
    )
  }

  ngOnInit() {
    this.listImportTrouble = [
      {title: "There is an issue when importing this file", images: this.imagesImportTrouble[0], description: "Possibly caused by unexpected data format, iDDR didn't cover the data in the file correctly", solution: "Capture the error and send the file with the error to iDDR Dev team for analyzing"},
      {title: "Unable to process file information as File Type", images: this.imagesImportTrouble[1], description: "Possibly caused by missing important information from the file or iDDR cannot transform the file correctly", solution: "Capture the error and send the file with the error to iDDR Dev team for analyzing"},
      {title: "No importer for File Type Unknown-Unknown was found", images: this.imagesImportTrouble[2], description: "Possibly caused by the imported file not been defined in iDDR or the file name doesn't match the file name of specific file type in iDDR", solution: "If you know the File Type is included in iDDR, choose the correct file type and refresh the list. A list of defined file names can be found in the File Example tab. If it is a new file type, please send to Dev team a couple of the files and other information relating to thisnew file type (Mapping to EDM, etc.)"},
      {title: "Duplicate file type", images: this.imagesImportTrouble[3], description: "Possibly caused by the duplication of importing two files within the same file type", solution: "Remove one file and press the refresh button to update the document list"},
      {title: "One or more validation errors occurred", images: this.imagesImportTrouble[4], description: "Possibly caused by the data in file not matching with the validation in the EDM database", solution: "Capture the error and send the file with the error to iDDR Dev team for analyzing"},
      {title: "File is failed to upload. Unable to process file information as File Type", images: this.imagesImportTrouble[5], description: "Possibly caused by incorrect file type be chosen or the file format is not expected", solution: "Try to change the file type to correct one. If that is still failed, capture the error and send the file with the error to iDDR Dev team for analyzing"}
    ];

    this.listMappingTrouble = [
      {title: "Errors were found while importing data", images: this.imagesMappingTrouble[0], description: "Possibly caused by the incorrect logic in iDDR while converting the data to EDM model", solution: "Capture the error and send the file with the error to iDDR Dev team for analyzing"},
      {title: "An error was encountered while trying to save", images: this.imagesMappingTrouble[1], description: "Possibly caused by incorrect logic in iDDR ", solution: "Capture the error and send the file with the error to iDDR Dev team for analyzing"},
    ];

    this.listEDMTrouble = [
      {title: "No Drillstring/Survey header information was found", images: this.imagesEDMTrouble[0], description: "Possibly caused by missing the header in EDM database or user choose incorrect well/wellbore", solution: "Users can go to OpenWells and check information. If it's missing, please add  before processing in iDDR "},
    ];

    this.listToastMessageTrouble = [
      {title: "Internal Timeout Error", images: this.imagesToastMessage[0], description: "Possibly caused by the slow network that cannot complete the API request within 30s or some requests takes more time to complete (Ex: import pdf file)", solution: "Try again several times, if that is not solved, capture the error and send the file with those error details to iDDR Dev team for analyzing"},
      {title: "Service is not available", images: this.imagesToastMessage[1], description: "Possibly caused by the slow network that cannot verify the microservice connection within 30s or the microservice is down for technical reasons", solution: "Try refreshing the page several times, if that is not solved, capture the error and send it to iDDR Dev team for analyzing"},
      {title: "You do not have sufficient permission to access this page ", images: this.imagesToastMessage[2], description: "Possibly caused by trying to access the iDDR Automation (dashboard) page without being added to the AAD group", solution: "If you need to access this page, you can contact the P.O to verify the permissions and being added to the AAD group"},
    ];

    this.listConfigurationTrouble = [
      {title: "No documents were found for the selected Well/Wellbore", images: this.imagesConfiguration[0], description: "Possibly caused by choosing incorrectly Well/Wellbore, or no file had been uploaded to that Well/Wellbore", solution: "Verify the Well/Wellbore in the iDDR Automation is the same with the Well/Wellbore in the iDDR Uploader. Access the Uploader page to upload the file"},
    ]
  }
  
	closeTroubleshooting() {
    let getMainScreen = document.getElementsByClassName("app__main-screen")[0];
    let getTroubleshooting = document.getElementsByClassName("troubleshooting--screen")[0];
    getMainScreen.classList.remove('collapse');
		getTroubleshooting.classList.remove('opened');
	}
}
