export const TIME_OUT = 30000;
export const enum SOURCE_FILE {
    Local = "Local",
    Cloud = "Cloud"
}
export const TOAST_LIFE_NUMBER = 5000;
export const SIGNALR_TIMEOUT = 30000;
export const SIGNALR_KEEP_ALIVE = 10000;
export const ROLE_READWRITER = 'ReadWriter.User';
export const ROLE_SUBMIT = 'Submit.User';

export const CONSTANTS_STRING = 'string';
export const CONSTANTS_OBJECT = 'object';
export const CONSTANTS_BASE64 = 'base64';
export const CONSTANTS_BINARY = 'binary';
