<div class="user-profile-area">
	<div *ngIf="isDisplay; else signIn">
		<p-menu #menuUserProfile [popup]="true" [model]="userProfileItems" class="common-menu profile-menu"></p-menu>
		<button
			type="button"
			class="user-profile"
			(click)="menuUserProfile.toggle($event)"
			[ngClass]="isClickable ? '' : 'disable-click'"
		>{{ userName }}</button>
	</div>
	<ng-template #signIn>
		<a (click)="login()" class="btn-sign-in"><i class="pi pi-fw pi-sign-in login-icon"></i>Sign In</a>
	</ng-template>
	
	<p-dialog [(visible)]="isAboutUsDisplay" [modal]="true" resizable="false" class="common-dialog header-dialog">
		<p-header>About iDDR</p-header>
		<div class="grid grid-nogutter body-section text-center">
			<div class="col-4">
				<img class="hal-about-pic" src="../../../assets/images/about_vertical.png" alt="Hal logo" width="100%"/>
			</div>
			<div class="col-8">
				<div class="head-text">iDDR</div>
				<div class="text-intro">
					<i>Integrated Daily Drilling Report Automation Tool</i><br />
					<div class="text-version">
						<b>{{ versionApp }}</b> <br />
						<b>{{ buildApp }}</b> <br />
					</div>
				</div>
				<div class="text-footer">
					Developer by HPM - Halliburton Project Management<br />
					in conjunction with HDS - Halliburton Digitial Services<br /><br />
					Copyright © 2020 Halliburton
				</div>
			</div>
		</div>
	</p-dialog>

	<p-dialog [(visible)]="isVersionHistoryDisplay" [modal]="true" resizable="false" class="common-dialog header-dialog">
		<p-header>Version History</p-header>
		<div class="history-area" *ngFor="let item of releaseNotesList">
			<div class="version-text">
				Version: <b>{{ item?.version }}</b> - Date: <b>{{ item?.date }}</b>
			</div>
			<div class="head-line">Enhancements</div>
			<ul class="list-release">
				<li *ngFor="let enhancement of item?.enhancements">
					{{ enhancement }}
				</li>
			</ul>
			<div class="head-line">Bug Fixes</div>
			<ul class="list-release">
				<li *ngFor="let bugs of item?.bugFixes">{{ bugs }}</li>
			</ul>
		</div>
	</p-dialog>
</div>
