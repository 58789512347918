import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TOAST_LIFE_NUMBER } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor(private msg: MessageService) { }

  timeoutErrorMessage(zone: string): void {
		this.msg.add({
			severity: 'error', 
			summary: 'Time out', 
			detail: `Time out while getting ${zone}`,
			life: TOAST_LIFE_NUMBER
		});
	}

  internalErrorMessage(title: string = null, message: string = null): void {
    let msg = 'Service is not available';
    let sum = 'Connection failed';

    if (title != null) {
      sum = title;
    }
    if (message != null) {
      msg = message;
    }
    
    this.msg.add({
      severity: 'error', 
      summary: sum, 
      detail: msg,
      life: TOAST_LIFE_NUMBER,
    });
  }
}
