import { EnvService } from './env.service';

export const EnvServiceFactory = () => {
    // Create env
    const env = new EnvService();

    // Read environment variables from browser window
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env'] || {};

    // Assign environment variables from browser window to env
    // In the current implementation, properties from env.js overwrite defaults from the EnvService
	// We may want to remove the environment condition checks here due to multiple environments we have
    if (env.production) {
        for (const key in browserWindowEnv) {
            if (browserWindowEnv.hasOwnProperty(key)) {
                let value : string = window['__env'][key];
                if (!value.startsWith("${")) {
					if (typeof env[key] === "string") {
                    	env[key] = window['__env'][key];
					} else if(Array.isArray(env[key])) {
                    	env[key] = [window['__env'][key]];
					}
                }
            }
        }
    }
    return env;
};

export const EnvServiceProvider = {
    provide: EnvService,
    useFactory: EnvServiceFactory,
    deps: [],
};
