import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ViewDetailsService {
	public responseData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public newReportData$: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);
	public enabledTabmenu$: BehaviorSubject<[]> = new BehaviorSubject<[]>(null);
	data = this.enabledTabmenu$.asObservable();

	constructor() {}

	nextData(item) {
		this.responseData$.next(item);
		let ds = 'dsd';
	}

	nextNewData(newItem) {
		this.newReportData$.next(newItem);
	}
	getEnabledTabmenu(item) {
		this.enabledTabmenu$.next(item);
	}
}
