import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConnectionStringToastService {
	public isConnectionFailed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	data = this.isConnectionFailed$.asObservable();
	constructor() {}

	getConnectionStatus(item) {
		this.isConnectionFailed$.next(item);
	}
}
