import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentEdmDataClass } from '@models/edmdata.model';
import { Observable } from 'rxjs';
import { EnvService } from './env.service';


@Injectable({
	providedIn: 'root',
})
export class SaveWellService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};
	constructor(
		private http: HttpClient,
		private env: EnvService
	) {}

	saveReports(body: ParentEdmDataClass): Observable<any> {
		let url = `${this.env.W_Connections_EdmHost}/api/save`;
		const options = {
			params: {},
			reportProgress: true,
		};

		return this.http
			.post(url, body, options);
	}
}
