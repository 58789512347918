import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { filter } from "rxjs/operators";
import { EnvService } from "./env.service";

@Injectable()
export class LoggingService {
	appInsights: ApplicationInsights;

	constructor(
		private router: Router,
		private env: EnvService,
		private activatedRoute: ActivatedRoute
	){
		var angularPlugin = new AngularPlugin();
		this.appInsights = new ApplicationInsights({
			config: {
        instrumentationKey: this.env.Application_Insights,
        enableAutoRouteTracking: true, // option to log all route changes
				extensionConfig: {
					[angularPlugin.identifier]: { router: this.router },
				},
				enableRequestHeaderTracking: true,
				loggingLevelConsole: 1, //log error only
				loggingLevelTelemetry: 1, //log error only
      }
		});
		this.appInsights.loadAppInsights();

		this.router.events
			.pipe(
      	filter(event => event instanceof NavigationEnd)
			)
			.subscribe((page: NavigationEnd) => {
				const lastChild = child => child.firstChild ? lastChild(child.firstChild) : child;
				this.logPageView(lastChild(this.activatedRoute.firstChild).snapshot.data.title, page.url);
			});
	}

	logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

	logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

	logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

	logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}
