import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';
import { ToastMessageService } from './toast-message.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

	constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
		private toastMessageService: ToastMessageService
  ) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    let timeoutValueNumeric = Number(timeoutValue);
		let lastResponse: HttpEvent<any>;
  	let error: HttpErrorResponse;

		return next
			.handle(request)
			.pipe(
				tap((response: HttpEvent<any>) => {
					lastResponse = response;
					if (response.type === HttpEventType.Response) {
						// TODO: success response
					}
				}),
				timeout(timeoutValueNumeric),
				catchError((err) => {
					if (err instanceof TimeoutError) {
						throw err;
					} else {
						error = err;
						// Check only 1 case Retrieve File from CosmosDB
						let checkURLRetrieve = error.url.includes('/stored/retrieve');

						// TODO: for each specific status
						// switch(err.status) {
						// 	case 400: break;
						//  case 401: break;
						// 	case 403: break;
						//  .....
						// }
						if(checkURLRetrieve) {
							this.toastMessageService.internalErrorMessage('Retrieve files from cloud', 'No documents were found for the selected Well/Wellbore');
							return throwError(error); 
						}
						
						if (request.url.includes('/checkconnection')) {
							return throwError(error); 
						}
						//ignore toast message when code is 422
						if (error.status == 422) {
							return throwError(error);
						}
						
						console.error(
							`Backend returned code ${error.status}, ` +
								`url: ${error.url}`
						);
						return throwError(error);  
					}
				}),
				finalize(() => { 
					if (lastResponse.type === HttpEventType.Sent && !error) {
						// TODO: Aborted request
						// last response type was 0, and we haven't received an error
					}
				})
			);
	}
}

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
