import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: 'dashboard',
		loadChildren: () =>
			import('././dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
	},
	{
		path: 'view-data',
		loadChildren: () =>
			import('././view-data/view-data.module').then(
				(m) => m.ViewDataModule
			),
	},
	{
		path: 'confirmation',
		loadChildren: () =>
			import('././confirmation/confirmation.module').then(
				(m) => m.ConfirmationModule
			),
	},
	{
		path: 'uploader',
		loadChildren: () =>
			import('././uploader/uploader.module').then(
				(m) => m.UploaderModule
			),
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)], // , {preloadingStrategy: PreloadAllModules}
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
