import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, TimeoutError } from 'rxjs';
import { EnvService } from './env.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastMessageService } from './toast-message.service';

@Injectable({
	providedIn: 'root',
})
export class IndicatorService {
	options = {
		params: {},
		reportProgress: true,
	};

	constructor(
		private http: HttpClient,
		private env: EnvService,
		private toastMessageService: ToastMessageService
	) { }

	public isImportConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	public isMappingConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);
	public isEDMConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		null
	);

	checkImportConnection(): Observable<any> {
		const url = `${this.env.W_Connections_ImportHost}/api/info/checkconnection`;
		return this.http
			.get(url, this.options)
	}

	checkMappingConnection(): Observable<any> {
		const url = `${this.env.W_Connections_MappingHost}/api/info/checkconnection`;
		return this.http
			.get(url, this.options)
	}

	checkEDMConnection(): Observable<any> {
		let url = `${this.env.W_Connections_EdmHost}/api/info/checkconnection`;
		return this.http
			.get(url, this.options)
	}

	checkAllConnection(): Observable<any> {
		return forkJoin([
			this.checkEDMConnection().pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('checking EDM connection');
						console.error('Time out while checking EDM connection');
					} else {
						this.toastMessageService.internalErrorMessage(`The EDM service is disconnected`);
						console.error('EDM disconnected');
					}
					return of(false);
				})
			),
			this.checkMappingConnection().pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('checking Mapping connection');
						console.error('Time out while checking Mapping connection');
					} else {
						this.toastMessageService.internalErrorMessage(`The Mapping service is disconnected`);
						console.error('Mapping disconnected');
					}
					return of(false);
				})
			),
			this.checkImportConnection().pipe(
				catchError((error) => {
					if (error instanceof TimeoutError) {
						this.toastMessageService.timeoutErrorMessage('checking Import connection');
						console.error('Time out while checking Import connection');
					} else {
						this.toastMessageService.internalErrorMessage(`The Import service is disconnected`);
						console.error('Import disconnected');
					}
					return of(false);
				})
			),
		])
	}
}
