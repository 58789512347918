import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { SIGNALR_KEEP_ALIVE, SIGNALR_TIMEOUT } from "./constant.service";
import { EnvService } from "./env.service";
import { LoggingService } from "./logging.service";
import { ToastMessageService } from "./toast-message.service";

@Injectable({
	providedIn: 'root',
})

export class SignalRService {
    constructor(
		private loggingService: LoggingService,
		private toastMessageService: ToastMessageService,
		private env: EnvService,
	) {}

    methodNameImport: "SendImportResult"

    setupSignalR(): signalR.HubConnection {
		const connection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Information)
			.withUrl(this.env.W_Connections_ImportHost + '/hub/import', {
				withCredentials: false,
				logger: signalR.LogLevel.Error,
				logMessageContent: false,
			})
			.build();
		connection.serverTimeoutInMilliseconds = SIGNALR_TIMEOUT;
		connection.keepAliveIntervalInMilliseconds = SIGNALR_KEEP_ALIVE;
		return connection;
	}

    openSignalR(connection: signalR.HubConnection): Promise<void> {
        if (connection != undefined && connection != null && connection.state != signalR.HubConnectionState.Connected) {
            return connection.start();
        }
        return undefined;
	};

    closeMethod(connection: signalR.HubConnection, methodName: string) {
        if (connection.state == signalR.HubConnectionState.Connected) {
            connection.off(methodName);
        }
    }

    closeSignalR(connection: signalR.HubConnection) {
		connection
			.stop()
			.catch((err) => {
        this.loggingService.logException(err, 3);
				return console.error(err.toString());
			});
	}
}