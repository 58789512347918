import { LoggingService } from './services/logging.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	PublicClientApplication,
} from '@azure/msal-browser';
import { AuthService } from '@services/auth.service';
import { SaveWellService } from '@services/save-well.service';

import { DashboardCommunicationService } from '../app/services/dashboard-communication.service';
import { DashboardService } from '../app/services/dashboard.service';
import { ViewDetailsService } from '../app/services/view-details.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonsModule } from './commons/commons.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConnectionStringToastService } from './services/connection-string-toast.service';
import { RefreshWellListService } from './services/refresh-well-list.service';
import { AuthInterceptor } from '@services/auth.interceptor';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import {
	EnvServiceFactory,
	EnvServiceProvider,
} from '@services/env.service.provider';
import {
	DEFAULT_TIMEOUT,
	HttpConfigInterceptor,
} from '@services/http-config.interceptor';
import { ErrorDialogService } from '@services/error-dialog.service';
import { TIME_OUT } from '@services/constant.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SignalRService } from '@services/signalR.service';
import { TroubleShootingComponent } from './trouble-shooting/trouble-shooting.component';
import { LightboxModule } from 'primeng/lightbox';
import { FieldsetModule } from 'primeng/fieldset';

let env = EnvServiceFactory();

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: env.Auth_ClientId,
			redirectUri: `${window.location.origin}`,
			authority: `https://login.microsoftonline.com/${env.Auth_TenantId}/`,
			postLogoutRedirectUri: '/',
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: false,
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(`*`, env.Auth_Scope);

	return {
		interactionType: InteractionType.Popup,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Popup,
		authRequest: {
			scopes: env.Auth_Scope,
			redirectUri: `${window.location.origin}/blank.html`,
		},
	};
}

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		TroubleShootingComponent,
	],
	imports: [
		CommonsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MsalModule,
		ToastModule,
		FontAwesomeModule,
		LightboxModule,
		FieldsetModule,
	],
	providers: [
		EnvServiceProvider,
		ViewDetailsService,
		SaveWellService,
		ConnectionStringToastService,
		RefreshWellListService,
		DashboardCommunicationService,
		DashboardService,
		SignalRService,
		LoggingService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: ApplicationinsightsAngularpluginErrorService,
		},
		{ provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpConfigInterceptor,
			multi: true,
		},
		{
			provide: DEFAULT_TIMEOUT,
			useValue: TIME_OUT,
		},
		ErrorDialogService,
		MessageService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
