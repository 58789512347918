import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
	MsalService,
	MsalBroadcastService,
	MSAL_GUARD_CONFIG,
	MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
	AccountInfo,
	AuthenticationResult,
	BrowserAuthError,
	InteractionRequiredAuthError,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
} from '@azure/msal-browser';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import {
	BehaviorSubject,
	Observable,
	Subject,
	interval,
	throwError,
} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ROLE_READWRITER } from './constant.service';
import { EnvService } from './env.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	private readonly _destroying$ = new Subject<void>();
	public accessToken: BehaviorSubject<string> = new BehaviorSubject<string>(
		null
	);

	accountName = '';
	accountId = '';
	isReadWriteUser: boolean = false;

	constructor(
		@Inject(MSAL_GUARD_CONFIG)
		private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private env: EnvService
	) {
		if (!this.accessToken.getValue()) {
			this.acquireAccessToken();
		}
		interval(300000)
			.pipe(takeUntil(this._destroying$))
			.subscribe((x) => {
				this.acquireAccessToken();
			});
	}
	ngOnDestroy(): void {
		this.destroy();
	}

	updateLoggedInStatus() {
		this.msalBroadcastService.inProgress$
			.pipe(
				filter(
					(status: InteractionStatus) =>
						status === InteractionStatus.None
				),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.checkAndSetActiveAccount();
				this.setLoggedIn();
			});
	}

	public resetData() {
		this.accountName = '';
		this.accountId = '';
		this.isReadWriteUser = false;
		this.loggedIn.next(false);
		this.accessToken.next(null);
	}

	acquireAccessToken(): Observable<string> {
		const accessTokenRequest = {
			scopes: this.env.Auth_Scope,
			clientId: this.env.Auth_ClientId,
			authority: `https://login.microsoftonline.com/${this.env.Auth_TenantId}/`,
			redirectUri: `${window.location.origin}/blank.html`,
		};
		this.checkAndSetActiveAccount();
		if (this.getActiveAccount()) {
			this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
				(res) => {
					if (!res.accessToken) {
						this.login();
						this.destroy();
					} else {
						if (this.accessToken.getValue() != res.accessToken) {
							this.accessToken.next(res.accessToken);
							if (!this.loggedIn.getValue()) {
								this.setLoggedIn();
							}
						}
					}
				},
				(err) => {
					if (err instanceof InteractionRequiredAuthError) {
						this.loggedIn.next(false);
						this.clearCache();
						window.location.reload();
					}
				}
			);
		}

		return this.accessToken.asObservable();
	}

	clearCache() {
		window.sessionStorage.clear();
		for (let item in window.localStorage) {
			if (
				item == 'currentVersion' ||
				item == 'isDontShowAgainChecked' ||
				item == 'selectionStorage' ||
				item == 'selectionUploaderStorage'
			) {
				continue;
			}
			window.localStorage.removeItem(item);
		}
	}

	login() {
		this.resetData();
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			this.loginWithPopup();
		} else {
			this.loginWithRedirect();
		}
	}

	getActiveAccount(): AccountInfo | null {
		return this.authService.instance.getActiveAccount();
	}

	private checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 */

		let activeAccount = this.getActiveAccount();

		if (
			!activeAccount &&
			this.authService.instance.getAllAccounts().length > 0
		) {
			let accounts = this.authService.instance.getAllAccounts();
			activeAccount = accounts[0];
			this.authService.instance.setActiveAccount(activeAccount);
		}
		if (activeAccount) {
			this.accountName = activeAccount.name;
			this.accountId = activeAccount.localAccountId;
			let roles: string[] = activeAccount.idTokenClaims['roles'] || [];
			this.isReadWriteUser = roles.some((e) => e == ROLE_READWRITER);
		}
	}

	private setLoggedIn() {
		let isLogged =
			this.authService.instance.getAllAccounts().length > 0 &&
			this.accessToken.getValue() != null;
		this.loggedIn.next(isLogged);
	}

	private loginWithPopup() {
		if (this.msalGuardConfig.authRequest) {
			this.authService
				.loginPopup({
					...this.msalGuardConfig.authRequest,
				} as PopupRequest)
				.subscribe(
					(response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(
							response.account
						);
						this.accountName = response.account.name;
						this.accountId = response.account.localAccountId;
						let roles: string[] =
							response.account.idTokenClaims['roles'] || [];
						this.isReadWriteUser = roles.some(
							(e) => e == ROLE_READWRITER
						);
						this.accessToken.next(response.accessToken);
					},
					(error) => {
						if (error instanceof BrowserAuthError) {
							window.sessionStorage.removeItem(
								`msal.${this.env.Auth_ClientId}.interaction.status`
							);
						}
					}
				);
		} else {
			this.authService
				.loginPopup()
				.subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(
						response.account
					);
					this.accountName = response.account.name;
					this.accountId = response.account.localAccountId;
					let roles: string[] =
						response.account.idTokenClaims['roles'] || [];
					this.isReadWriteUser = roles.some(
						(e) => e == ROLE_READWRITER
					);
					this.accessToken.next(response.accessToken);
				});
		}
	}

	private loginWithRedirect() {
		if (this.msalGuardConfig.authRequest) {
			this.authService.loginRedirect({
				...this.msalGuardConfig.authRequest,
			} as RedirectRequest);
		} else {
			this.authService.loginRedirect();
		}
	}

	logout(isRedirected: boolean = false) {
		if (isRedirected) {
			this.authService
				.logoutRedirect({
					postLogoutRedirectUri: '/',
					account: this.getActiveAccount(),
				})
				.subscribe(() => {
					this.resetData();
				});
		} else {
			this.authService
				.logoutPopup({
					postLogoutRedirectUri: '/',
					account: this.getActiveAccount(),
				})
				.subscribe(() => {
					this.resetData();
					window.location.reload();
				});
		}
	}

	destroy() {
		this.resetData();
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
}
