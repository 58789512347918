<div class="wrapper">
	<div class="app__main-screen">
		<app-header></app-header>
		<div class="app__body-content">
			<app-menu></app-menu>
			<router-outlet></router-outlet>
		</div>
	</div>
	<div class="troubleshooting--screen">
		<app-trouble-shooting></app-trouble-shooting>
	</div>
	<p-toast position="top-right" [baseZIndex]="3" class="common-toast-message">
		<ng-template let-message pTemplate="message">
			<fa-icon class="message-icon" [icon]="faTimesCircle"></fa-icon>
			<div class="content-message">
				<div class="summary"><b>{{message.summary}}</b></div>
				<div>{{message.detail}}</div>
			</div>
		</ng-template>
	</p-toast>
</div>

