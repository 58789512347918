<div class="iDDR__menu-layout">
  <ul class="menu--list">
    <li *ngFor="let item of menuItems" (click)="onMenuItemClick(item)" [ngClass]="item?.disabled ? 'disabled' : ''" [title]="item?.title">
      <span class="menu-icon"><fa-icon [icon]="item.icon"></fa-icon></span>
      <span class="menu-title">{{ item.label }}</span>
    </li>
  </ul>
</div>

<p-dialog [(visible)]="isFileExampleDisplay" [modal]="true" resizable="false" class="common-dialog header-dialog filetype-example-dialog">
  <p-header>File Example</p-header>
  <p-tabView class="filetype-example-tabview">
    <p-tabPanel  [selected]="i==0"  header="{{item?.tab}}" *ngFor="let item of fileExample,let i = index">
      <p-tabView class="tabview-inner">
        <table class="report-filetype text-left">
          <tr>
            <th>Received From:</th>
            <td>{{item?.receivedFrom}}</td>
          </tr>
          <tr>
            <th>Type of File:</th>
            <td>{{item?.typeOfFile}}</td>
          </tr>
          <tr>
            <th>File Type (in dropdown list):</th>
            <td>{{item?.fileType}}</td>
          </tr>
          <tr>
            <th>File Name Keywords:</th>
            <td>{{item?.keyword}}</td>
          </tr>
          <tr>
            <th>File Name Example:</th>
            <td>{{item?.fileExample}}</td>
          </tr>
          <tr>
            <th>Other Notes:</th>
            <td>{{item?.note}}</td>
          </tr>
        </table>
      </p-tabView>
    </p-tabPanel>
  </p-tabView>
</p-dialog>
