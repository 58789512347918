<div class="header">
  <div class="first-content">
    <button type="button" pButton [icon]="menuButtonIcon" class="menu-btn"
    (click)="onMenuBtnClick()"></button>
	  <div class="app-full-name h3">{{ appHeaderName }}</div>
  </div>
  
  <div class="well-information" *ngIf="isRouterDisplay">{{ wellName }} <span>-</span> {{ wellBoreName }} <span>-</span> {{ dailyReport }}</div>

  <div class="third-content">
    <div class="container-connection">
      <div [ngClass]="isEDMConnect ? 'enabled-existing': 'disabled-existing'" title="EDM status">
        <fa-icon [icon]="faCircle"></fa-icon>
      </div>
      <div [ngClass]="isMappingConnect ? 'enabled-existing': 'disabled-existing'" title="Mapping status">
        <fa-icon [icon]="faCircle"></fa-icon>
      </div>
      <div [ngClass]="isImportConnect ? 'enabled-existing': 'disabled-existing'" title="Import status">
        <fa-icon [icon]="faCircle"></fa-icon>
      </div>
    </div>

    <app-login></app-login>
  </div>
  
</div>
