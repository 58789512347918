export const environment = {
	production: true,
	W_Connections_ImportHost: "https://iddr-dev.ienergy.halliburton.com/import",
	W_Connections_MappingHost: "https://iddr-dev.ienergy.halliburton.com/map",
	W_Connections_EdmHost: "https://iddr-dev.ienergy.halliburton.com/edm",
	W_Connections_WorkflowHost: "https://iddr-dev.ienergy.halliburton.com/work",
	Auth_ClientId: "12a04538-42c9-4bb5-b9ef-bfa7c7c22eb0",
	Auth_TenantId: "b7be7686-6f97-4db7-9081-a23cf09a96b5",
	Auth_Scope: ["api://df2dce5a-d2ad-4123-82fe-c9ca73c1a999/.default"],
	Application_Insights: '49e0971b-8cc4-44ea-8fda-fcaff1bca830',
	NODE_ENV: 'prod'
};